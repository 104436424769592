import { useMemo } from 'react'

import { Cell, PieChart, Pie, Legend, Tooltip, ComposedChart, ResponsiveContainer, Bar, Line, CartesianGrid, XAxis, YAxis } from 'recharts'
import { useRouter } from 'next/router'
import Layout from 'components/Layout'
import Statistic from '@byma/shared/components/Statistic'
import { methodParser, money } from '@byma/shared/helpers/formatters'
import DateFilter from '@byma/shared/components/DateFilter'
import EventFilter from '@byma/shared/components/EventFilter'
import ChannelFilter from '@byma/shared/components/ChannelFilter'
import TicketTypesOverview from 'components/TicketTypesOverview'
import ChannelsOverview from 'components/ChannelsOverview'
import CouponsOverview from 'components/CouponsOverview'
import Tabs from '@byma/shared/components/Tabs'
import Loader from '@byma/shared/components/Loader'
import Gauge from '@byma/shared/components/Gauge'
import useAuth from '@byma/shared/hooks/useAuth'
import fetcher from '@byma/shared/helpers/fetcher'
import useSWR from 'swr'
import dayjs from 'dayjs'
import useFilters from '@byma/shared/hooks/useFilters'
import { FiCalendar } from 'react-icons/fi'

function reportSum(report, key) {
    if (!report) return 0
    return report.reduce((acc, cur) => acc += cur[key], 0)
}

function getDaysArray(s,e) {for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;}

const PIE_COLORS = [
    '#DA3A6A',
    '#2CA772',
    '#E14E14',
    '#4A9A07',
    '#9B0734',
    '#F37340',
    '#E02F4E',
    '#219B7C',
    '#5BCD2B',
    '#98E777',
]

function EventsOverview({ticketReport}) {
    const eventReportObj = ticketReport?.result?.reduce((acc, cur) => {
        const existingPartyKey = acc[cur.party._id]
        if (!existingPartyKey) {
            acc[cur.party._id] = {
                name: cur.party.name,
                totalTicketTypeValue: cur.count * cur.ticketType.cost,
                totalCount: cur.count,
                totalTarget: cur.target || null,
            }
        } else {
            acc[cur.party._id] = {
                ...existingPartyKey,
                totalTicketTypeValue: existingPartyKey.totalTicketTypeValue + (cur.count * cur.ticketType.cost),
                totalCount: existingPartyKey.totalCount + cur.count,
                totalTarget: cur.target ? (existingPartyKey.totalTarget ? (cur.target + existingPartyKey.totalTarget) : cur.target) : existingPartyKey.totalTarget,
            }
        }
        return acc
    }, {}) || {}

    return (
        <div className="w-full">
            {Object.values(eventReportObj).sort((a,b) => b.totalTicketTypeValue - a.totalTicketTypeValue).map((item, index) => (
                <div className="flex justify-between items-center w-full hover:bg-gray-100 rounded p-3">
                    <div className="flex flex-col">
                        <p className="text-md">{index + 1}. <span className="font-bold">{item.name}</span></p>
                    </div>
                    <div className="flex flex-col items-end justify-end">
                        <p className="font-bold text-md">{money(item.totalTicketTypeValue)}</p>
                        {item.totalTarget ? (
                            <p className="text-sm">{item.totalCount} de {item.totalTarget} vendidos (x%)</p>
                        ) : (
                            <p className="text-sm">{item.totalCount} vendidos</p>
                        )}
                    </div>
                </div>
            )) || <Loader />}
        </div>
    )
}


export default function Home() {
    const session = useAuth()
    const { token, user: currentUser } = session
    const { query, ...router } = useRouter()
    const { parsedFilters, queryString, rawFilters } = useFilters(query)
    const { data: paymentReport } = useSWR(token ? [`/api/payments/report?${queryString}`, null, token] : null, fetcher)
    const { data: ticketReport } = useSWR(token ? [`/api/tickets/report?${queryString}`, null, token] : null, fetcher)
    const { data: channelReport } = useSWR(token ? [`/api/channels/report?${queryString}`, null, token] : null, fetcher)
    const { data: couponReport } = useSWR(token ? [`/api/coupons/report?${queryString}`, null, token] : null, fetcher)
    const { data: ticketTypes } = useSWR(token ? [`/api/tickettypes/getAll?${queryString}`, null, token] : null, fetcher)

    const tabs = [
        { title: 'Lotes', content: <TicketTypesOverview {...{ticketReport}} /> },
        { title: 'Canais', content: <ChannelsOverview {...{channelReport}} /> },
        { title: 'Cupons', content: <CouponsOverview {...{couponReport}} /> },
    ] 

    const reportDateObj = useMemo(() => (
        paymentReport ? paymentReport.result.reduce((acc, cur) => {
            if (!acc[cur._id.created_at]) {
                acc[cur._id.created_at] = { count: 0, order_amount_cents: 0 }
            } 
            acc[cur._id.created_at].count += cur.count
            acc[cur._id.created_at].order_amount_cents += cur.order_amount_cents
            return acc
        }, {}) : {}
    ), [paymentReport])

    const paymentReportByMethod = useMemo(() => {
        if (!paymentReport) {
            return []
        }

        let tmpObj = paymentReport.result.reduce((acc, cur) => {
            const method = `${cur._id.method}`
            if (!acc[method]) {
                acc[method] = {
                    method: cur._id.method,
                    name: `${methodParser[cur._id.method]}`,
                    order_amount_cents: cur.order_amount_cents / 100 ,
                    total_amount_cents: cur.total_amount_cents / 100
                }
                return acc
            }
            acc[method].order_amount_cents += cur.order_amount_cents / 100
            acc[method].total_amount_cents += cur.total_amount_cents / 100
            return acc
        }, {})

        return Object.keys(tmpObj).map(k => tmpObj[k])
    }, [paymentReport])

    const dateArray = useMemo(() => {
        let { fromDate, toDate } = Object.assign({}, parsedFilters)

        if (!fromDate) {
            fromDate = dayjs().subtract(1, 'year')
        }
        if (!toDate) {
            toDate = dayjs()
        }
        return getDaysArray(new Date(fromDate), new Date(toDate)).map(d => dayjs(d).format('YYYY-MM-DD'))
    }, [parsedFilters])

    const chartDataArray = useMemo(() => dateArray.reduce((acc, d) => {
        if (Object.keys(acc).length === 0 && !reportDateObj[d]?.count) {
            return acc
        }
        return [...acc, { date: dayjs(d).format('DD/MM/YY'), count: reportDateObj[d]?.count || 0, order_amount_cents: reportDateObj[d]?.order_amount_cents / 100 || 0 }]
    }, []), [dateArray, reportDateObj])

    /*
    const disponibilityPercentage = useMemo(() => {
        if (ticketTypes?.result?.length === 0) {
            return 0
        }
        if (ticketTypes?.result) {
            const sums = ticketTypes.result.reduce((acc, cur) => ({
                targetSum: acc.targetSum + (cur.target || 0),
                soldSum: acc.soldSum + (cur.ticketsSold || 0),
            }), {
                soldSum: 0,
                targetSum: 0,
            })        
            return (sums.soldSum / sums.targetSum * 100).toFixed(2)
        }
        return false
    }, [ticketTypes])
    */

    return (
        <Layout {...{session}}>
            <div className="p-3">
                <div className="flex flex-col mb-3">
                    <p className="text-2xl font-bold">{renderGreeting()}, {currentUser?.name.split(' ')[0]}!</p>
                    <p className="text-md text-gray-800">Aqui est&atilde;o suas estat&iacute;sticas de hoje, {dayjs().format('DD [de] MMMM [de] YYYY').toLowerCase()}</p>
                </div>
                <div className="flex space-x-3 mb-6 overflow-y-auto py-3">
                    <DateFilter activeKey={rawFilters.dateKey} />
                    <EventFilter token={token} filteredEvents={rawFilters.parties} />
                    <ChannelFilter token={token} filteredChannels={rawFilters.channels} />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    <div>
                        <Statistic 
                            title="Receita total"
                            value={money(reportSum(paymentReport?.result, 'order_amount_cents') / 100)}
                            subText={reportSum(paymentReport?.result, 'count') + ' vendas'}
                            loading={!paymentReport}
                            sparkline={Object.entries(reportDateObj).sort(([a],[b]) => a > b).map(([_, i]) => i.order_amount_cents)}
                        />
                    </div>
                    <div>
                        <Statistic
                            title="Ingressos" 
                            sparkline={[]}
                            value={reportSum(ticketReport?.result, 'count')}
                            loading={!ticketReport}
                            subText={(paymentReport?.result?.length ? (reportSum(ticketReport?.result, 'count') / reportSum(paymentReport?.result, 'count')).toFixed(1) : 0) + " ingressos/venda"}
                        />
                    </div>
                    <div>
                        <Statistic
                            title="Ticket M&eacute;dio"
                            sparkline={[]}
                            loading={!(paymentReport && ticketReport)}
                            value={paymentReport?.result?.length ? money(reportSum(paymentReport?.result, 'order_amount_cents') / 100 / reportSum(paymentReport?.result, 'count')) : 0}
                        />
                    </div>
                </div>
                <div className="flex my-3 h-64 w-full">
                    <ResponsiveContainer>
                        <ComposedChart width={500} height={400} data={chartDataArray}>
                            <Bar yAxisId="right" name="Vendas" type="monotone" dataKey="count" fill="#ea560d" />
                            <Line yAxisId="left" name="Total (R$)" type="monotone" dataKey="order_amount_cents" stroke="#98021D" strokeWidth={2} />
                            <CartesianGrid stroke="#ccc" />
                            <XAxis dataKey="date" />
                            <YAxis yAxisId="left" unit="R$" />
                            <YAxis yAxisId="right" orientation="right"  />
                            <Legend />
                            <Tooltip />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    <div className="box p-0">
                        <p className="text-md tracking-tight border-b w-full p-3">Eventos Mais Vendidos</p>
                        <div className="overflow-y-auto h-80">
                            <EventsOverview {...{ticketReport}} />
                        </div>
                    </div>
                    <div className="box p-0 col-span-2">
                        <p className="text-md tracking-tight border-b w-full p-3">Métodos de Pagamento</p>
                        <div className="h-80 p-3">
                            <ResponsiveContainer>
                                <PieChart width={730} height={5}>
                                    <Pie data={paymentReportByMethod} dataKey="order_amount_cents" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} label={({order_amount_cents}) => money(order_amount_cents)}>
                                        {paymentReportByMethod.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Legend />
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                {/*
                <div className="flex flex-col md:flex-row gap-3 divide-x-0 md:divide-x md:h-120 items-center"> 
                    <div className="md:w-2/3 h-120">
                        <Tabs tabs={tabs} />
                    </div>
                    <div className="md:w-1/3 h-full">
                        {ticketTypes ? (
                            <Gauge value={disponibilityPercentage} colors={['red', 'blue']} />
                        ) : <Loader />}
                    </div>
                </div>
                <div className="h-120">
                    <Tabs tabs={tabs} />
                </div>
                */}
            </div>
        </Layout>
    )
}

function renderGreeting() {
    var today = new Date()
    var curHr = today.getHours()

    if (curHr < 12) {
        return 'Bom dia'
    } else if (curHr < 18) {
        return 'Boa tarde'
    } else {
        return 'Boa noite'
    }
}

